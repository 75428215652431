// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/transaction.proto (package kikoff_canada.protobuf.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.models.Transaction
 */
export class Transaction extends Message<Transaction> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string parent_id = 2;
   */
  parentId = "";

  /**
   * @generated from field: kikoff_canada.protobuf.models.Transaction.Operation operation = 3;
   */
  operation = Transaction.Operation.UNKNOWN;

  /**
   * @generated from field: kikoff_canada.protobuf.models.Transaction.Transactable creditable = 4;
   */
  creditable?: Transaction.Transactable;

  /**
   * @generated from field: kikoff_canada.protobuf.models.Transaction.Transactable debitable = 5;
   */
  debitable?: Transaction.Transactable;

  /**
   * @generated from field: uint32 amount_cents = 6;
   */
  amountCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp transacted_at = 7;
   */
  transactedAt?: Timestamp;

  constructor(data?: PartialMessage<Transaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.Transaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "operation", kind: "enum", T: proto3.getEnumType(Transaction.Operation) },
    { no: 4, name: "creditable", kind: "message", T: Transaction.Transactable },
    { no: 5, name: "debitable", kind: "message", T: Transaction.Transactable },
    { no: 6, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "transacted_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Transaction {
    return new Transaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Transaction {
    return new Transaction().fromJsonString(jsonString, options);
  }

  static equals(a: Transaction | PlainMessage<Transaction> | undefined, b: Transaction | PlainMessage<Transaction> | undefined): boolean {
    return proto3.util.equals(Transaction, a, b);
  }

  static _enums: {
    Operation: typeof Transaction.Operation,
  };
}

export namespace Transaction {
  /**
   * @generated from enum kikoff_canada.protobuf.models.Transaction.Operation
   */
  export enum Operation {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: REFUND = 1;
     */
    REFUND = 1,

    /**
     * @generated from enum value: PAYMENT = 2;
     */
    PAYMENT = 2,

    /**
     * @generated from enum value: SUBSCRIPTION_ACTIVATION = 3;
     */
    SUBSCRIPTION_ACTIVATION = 3,

    /**
     * @generated from enum value: STORE_PURCHASE = 4;
     */
    STORE_PURCHASE = 4,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Transaction.Operation)
  proto3.util.setEnumType(Transaction.Operation, "kikoff_canada.protobuf.models.Transaction.Operation", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "REFUND" },
    { no: 2, name: "PAYMENT" },
    { no: 3, name: "SUBSCRIPTION_ACTIVATION" },
    { no: 4, name: "STORE_PURCHASE" },
  ]);
}

export namespace Transaction {
  /**
   * @generated from message kikoff_canada.protobuf.models.Transaction.Transactable
   */
  export class Transactable extends Message<Transaction.Transactable> {
    /**
     * @generated from field: kikoff_canada.protobuf.models.Transaction.Transactable.Type type = 1;
     */
    type = Transaction.Transactable.Type.UNKNOWN;

    /**
     * @generated from field: string id = 2;
     */
    id = "";

    constructor(data?: PartialMessage<Transaction.Transactable>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "kikoff_canada.protobuf.models.Transaction.Transactable";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(Transaction.Transactable.Type) },
      { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Transaction.Transactable {
      return new Transaction.Transactable().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Transaction.Transactable {
      return new Transaction.Transactable().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Transaction.Transactable {
      return new Transaction.Transactable().fromJsonString(jsonString, options);
    }

    static equals(a: Transaction.Transactable | PlainMessage<Transaction.Transactable> | undefined, b: Transaction.Transactable | PlainMessage<Transaction.Transactable> | undefined): boolean {
      return proto3.util.equals(Transaction.Transactable, a, b);
    }

    static _enums: {
      Type: typeof Transaction.Transactable.Type,
    };
  }
}

export namespace Transaction.Transactable {
  /**
   * @generated from enum kikoff_canada.protobuf.models.Transaction.Transactable.Type
   */
  export enum Type {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUBSCRIPTION_INVOICE = 1;
     */
    SUBSCRIPTION_INVOICE = 1,

    /**
     * @generated from enum value: PAYMENT_METHOD = 2;
     */
    PAYMENT_METHOD = 2,

    /**
     * @generated from enum value: STORE_ORDER = 3;
     */
    STORE_ORDER = 3,

    /**
     * @generated from enum value: PAYOFF_FROM_KIKOFF = 4;
     */
    PAYOFF_FROM_KIKOFF = 4,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Transaction.Transactable.Type)
  proto3.util.setEnumType(Transaction.Transactable.Type, "kikoff_canada.protobuf.models.Transaction.Transactable.Type", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUBSCRIPTION_INVOICE" },
    { no: 2, name: "PAYMENT_METHOD" },
    { no: 3, name: "STORE_ORDER" },
    { no: 4, name: "PAYOFF_FROM_KIKOFF" },
  ]);
}

